import { UrlLink } from '@/configs/urlLink';
import _format from '@/utils/format';
import React, { useCallback, useMemo } from 'react';
import Link from 'next/link';
import { BlockFill, PlayBtnWhiteIcon } from '@/assets/icons';
import LazyImage from '../shared/LazyImage';
import { useBlockContext } from '@/context/useBlockContext';

export interface IPlaylistHomepage {
  is_block?: boolean | null;
  id: number;
  thumbnail_url?: string | null;
  name: string;
  description: string;
  updated_at?: any | null;
  category?: { __typename?: 'course_category'; name: string } | null;
  lessons_aggregate: {
    __typename?: 'lesson_aggregate';
    aggregate?: {
      __typename?: 'lesson_aggregate_fields';
      count?: number | null;
    } | null;
  };
}

export interface CardPlaylistProps {
  data?: IPlaylistHomepage;
  isLoading: boolean;
  nameFirstVideo: string;
  idFirstVideo: number;
  onClick?: () => void;
  onClickOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setClickedVideo: React.Dispatch<React.SetStateAction<string>>;
}
const CardPlaylistSkeleton = (
  <div className="bg-gray-variants-2 flex h-full w-56 animate-pulse flex-col gap-4 rounded-2xl p-4">
    <div className="bg-gray-variants-8 h-48" />
    <div className="bg-gray-variants-8 h-6 w-1/2 rounded-lg" />
    <div className="bg-gray-variants-8 h-6 w-full rounded-lg" />
  </div>
);

const CardPlaylist = ({
  data,
  isLoading,
  nameFirstVideo,
  idFirstVideo,
  onClickOpenModal,
  setClickedVideo,
}: CardPlaylistProps) => {
  const { block } = useBlockContext() as IBlockContext;
  const { thumbnail_url, name, lessons_aggregate, is_block } = data || {};

  const isOpenPlaylist = useCallback(
    (is_block_video: boolean) => {
      if (block.is === false) {
        return true;
      } else {
        if (!is_block_video) {
          return true;
        } else {
          return false;
        }
      }
    },
    [block.is],
  );

  const isOpenPlaylistCourse = useMemo(() => {
    return isOpenPlaylist(is_block || false);
  }, [isOpenPlaylist, is_block]);

  const handleClickItem = () => {
    const url = `${process.env.NEXT_PUBLIC_DOMAIN}${
      UrlLink.knowledge.knowledge
    }/${_format.convertTitleToSlug(nameFirstVideo || '')}-${idFirstVideo}`;
    if (!isOpenPlaylist(is_block || false)) {
      setClickedVideo(url);
      onClickOpenModal(true);
    }
  };

  return (
    <>
      {isLoading ? (
        CardPlaylistSkeleton
      ) : (
        <div
          className={`flex h-auto flex-col gap-4 bg-white px-2 ${
            isOpenPlaylistCourse
              ? 'duration-150 hover:scale-105'
              : 'cursor-not-allowed opacity-50'
          }`}
          onClick={handleClickItem}
        >
          {isOpenPlaylistCourse ? (
            <Link
              href={`${
                UrlLink.knowledge.knowledge
              }/${_format.convertTitleToSlug(
                nameFirstVideo || '',
              )}-${idFirstVideo}`}
            >
              <div className="relative h-[172px] w-full  md:h-[160px]">
                <LazyImage
                  src={thumbnail_url}
                  alt="Thumbnail course"
                  fill
                  sizes="(max-width: 768px) 25vw, (max-width: 1024px) 20vw, 25vw"
                  className="object-fit"
                />
                <PlayBtnWhiteIcon className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
              </div>
            </Link>
          ) : (
            <div className="relative h-[172px] w-full  md:h-[160px]">
              <LazyImage
                src={thumbnail_url}
                alt="Thumbnail course"
                fill
                sizes="(max-width: 768px) 25vw, (max-width: 1024px) 20vw, 25vw"
                className="object-fit grayscale"
              />
              <BlockFill className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
            </div>
          )}
          <div className="flex flex-col gap-1 p-3">
            {isOpenPlaylistCourse ? (
              <Link
                href={`${
                  UrlLink.knowledge.knowledge
                }/${_format.convertTitleToSlug(
                  nameFirstVideo || '',
                )}-${idFirstVideo}`}
              >
                <h3 className="text-PC-Display-H5 md:text-PC-Title-Large font-sans text-lg">
                  <p>{name}</p>
                </h3>
              </Link>
            ) : (
              <h3 className="text-PC-Display-H5 md:text-PC-Title-Large font-sans text-lg opacity-50">
                {name}
              </h3>
            )}

            <p
              className={`text-neutral-grayDark font-sans text-sm font-semibold ${
                isOpenPlaylistCourse ? '' : 'opacity-50'
              }`}
            >
              {lessons_aggregate?.aggregate?.count} Videos
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default CardPlaylist;
