import { UrlLink } from '@/configs/urlLink';
import { KnowledgeHomepageQuery } from '@/generated/graphql';
import _format from '@/utils/format';
import React from 'react';
import Link from 'next/link';
import { PlayBtnWhiteIcon } from '@/assets/icons';
import LazyImage from '../shared/LazyImage';

export interface CardPlaylistProps {
  data?: KnowledgeHomepageQuery['course'][number];
  isLoading: boolean;
  nameFirstVideo: string;
  idFirstVideo: number;
  onClick?: () => void;
}
const CardPlaylistSkeleton = (
  <div className="bg-gray-variants-2 flex h-full w-56 animate-pulse flex-col gap-4 rounded-2xl p-4">
    <div className="bg-gray-variants-8 h-48" />
    <div className="bg-gray-variants-8 h-6 w-1/2 rounded-lg" />
    <div className="bg-gray-variants-8 h-6 w-full rounded-lg" />
  </div>
);

const CardPlaylistFeature = ({
  data,
  isLoading,
  nameFirstVideo,
  idFirstVideo,
}: CardPlaylistProps) => {
  const { thumbnail_url, name, lessons_aggregate } = data || {};
  return (
    <>
      {isLoading ? (
        CardPlaylistSkeleton
      ) : (
        <Link
          className={`flex h-auto cursor-pointer flex-col gap-4 bg-[#FAFAFA] px-2 duration-150 hover:scale-105 ${
            lessons_aggregate?.aggregate?.count || 0 == 0 ? 'hidden' : ''
          }`}
          href={`${UrlLink.knowledge.knowledge}/${_format.convertTitleToSlug(
            nameFirstVideo || '',
          )}-${idFirstVideo}`}
        >
          <div className="relative h-[236px] w-full  md:h-[132px]">
            <LazyImage
              src={thumbnail_url}
              alt="Thumbnail course"
              fill
              sizes="(max-width: 768px) 100vw, (max-width: 768px) 50vw, 25vw"
              className="object-fit"
            />
            <PlayBtnWhiteIcon className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
          </div>
          <div className="flex flex-col gap-1 p-3">
            <h3 className="text-PC-Display-H5 md:text-PC-Title-Large font-sans text-lg">
              <p>{name}</p>
            </h3>
            <p className="text-neutral-grayDark font-sans text-sm font-semibold">
              {lessons_aggregate?.aggregate?.count} Videos
            </p>
          </div>
        </Link>
      )}
    </>
  );
};

export default CardPlaylistFeature;
