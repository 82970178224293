import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  Order_By,
  useGetAllCategoryKnowledgeQuery,
  useGetCourseByCategoryQuery,
} from '@/generated/graphql';
import { LazyImage } from '../shared';
import Link from 'next/link';
import { UrlLink } from '@/configs/urlLink';
import _format from '@/utils/format';
import {
  BlockFillLarge,
  LeftArrowIcon1,
  LockedCategoryIcon,
  RightArrowIcon1,
} from '@/assets/icons';
import HomeCardPlaylist from './HomeCardPlaylist';
import Slider, { Settings } from 'react-slick';
import HomeCardPlaylistFeature from './HomeCardPlaylistFeature';
import { useBlockContext } from '@/context/useBlockContext';
import InfomationModal from '../shared/InfomationModal';

const KnowledgePlaylistSection = () => {
  const { block } = useBlockContext() as IBlockContext;
  const [idClicked, setIdClicked] = useState('');
  const [categoryClicked, setCategoryClicked] = useState('');
  const { data: dataPlaylistCategory } = useGetAllCategoryKnowledgeQuery({
    variables: {
      orderBy: [
        {
          playlist_order: Order_By.AscNullsLast,
        },
      ],
    },
  });

  const filteredListCategory = dataPlaylistCategory?.course_category.filter(
    outerItem =>
      outerItem.course_course_categories.some(
        innerItem => innerItem?.course?.lessons?.length || 0 > 0,
      ),
  );

  const firstFalseVideoCategory = filteredListCategory?.find(
    item => item.is_block_video === false || item.is_block_video === null,
  );

  const isCourseCategory = (
    category: typeof firstFalseVideoCategory,
  ): category is NonNullable<typeof firstFalseVideoCategory> => {
    return category !== null;
  };

  const [currentCategoryId, setcurrentCategoryId] = useState(
    block.is === false
      ? filteredListCategory?.[0]?.id
      : isCourseCategory(firstFalseVideoCategory)
      ? firstFalseVideoCategory.id
      : '',
  );

  const { data: dataListCourse, loading } = useGetCourseByCategoryQuery({
    variables: {
      where: {
        course_course_categories: {
          course_category_id: {
            _eq: currentCategoryId,
          },
        },
      },
      orderBy: [
        {
          order: Order_By.AscNullsLast,
        },
      ],
    },
  });

  const settingsMobile: Settings = {
    dots: false,
    infinite: true,
    arrows: false,
    variableWidth: true,
  };

  const listCourse = useMemo(() => {
    return dataListCourse?.course.filter(item => item.lessons.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCategoryId, dataListCourse]);

  const customSlider = useRef<Slider>(null);
  const goToPrev = () => customSlider?.current?.slickPrev();
  const goToNext = () => customSlider?.current?.slickNext();

  const lengSlide = (listCourse?.length || 0) - 1;

  const [isOpenModal, setIsOpenModal] = useState(false);

  const isOpenCategory = useCallback(
    (is_block_video: boolean) => {
      if (block.is === false) {
        return true;
      } else {
        if (!is_block_video) {
          return true;
        } else {
          return false;
        }
      }
    },
    [block.is],
  );

  const handleClickCategory = (
    id: string,
    title: string,
    is_block_video: boolean,
  ) => {
    setCategoryClicked('Video - ' + title);
    setIdClicked(id);
    if (isOpenCategory(is_block_video)) {
      setcurrentCategoryId(id);
    } else {
      setIsOpenModal(true);
    }
  };

  const featureCourse = useMemo(() => {
    return listCourse?.[0];
  }, [listCourse]);

  const firstLessionNotBlock = featureCourse?.lessons?.find(
    item => item.is_block === false || item.is_block === null,
  );

  const isOpenFeatureCourse = useMemo(() => {
    return isOpenCategory(featureCourse?.is_block || false);
  }, [featureCourse?.is_block, isOpenCategory]);

  const handleClickFeaturePlaylist = () => {
    const url = `${process.env.NEXT_PUBLIC_DOMAIN}${
      UrlLink.knowledge.knowledge
    }/${_format.convertTitleToSlug(featureCourse?.lessons?.[0]?.name || '')}-${
      featureCourse?.lessons?.[0]?.id
    }`;
    if (!isOpenFeatureCourse) {
      setUrlRedirect(url);
      setIsOpenModalDetail(true);
    }
  };

  const [isOpenModalDetail, setIsOpenModalDetail] = useState(false);
  const [urlRedirect, setUrlRedirect] = useState<string>('');

  return (
    <div className="bg-white">
      {isOpenModal && (
        <InfomationModal
          setShowModel={setIsOpenModal}
          setCurrentCategory={setcurrentCategoryId}
          title={categoryClicked}
          idCategory={idClicked}
        />
      )}

      {isOpenModalDetail && (
        <InfomationModal
          setShowModel={setIsOpenModalDetail}
          title={urlRedirect || ''}
        />
      )}

      <div className="container pt-6">
        <div className="bg-white">
          <div className="border-neutral-grayLight flex flex-wrap items-center justify-between px-2 md:p-3">
            <div className="md:pb-4">
              <h2 className="font-header py-4 text-2xl font-semibold md:py-0">
                Kiến thức đầu tư
              </h2>
            </div>
            <div className="relative w-full">
              <div className="no-scrollbar w-full overflow-x-scroll pb-2 text-center">
                <ul className="flex">
                  {filteredListCategory?.map((item, index) => (
                    <li className="" key={index}>
                      <button
                        onClick={() =>
                          handleClickCategory(
                            item.id,
                            item.name,
                            item.is_block_video || false,
                          )
                        }
                        className={`flex flex-row items-center justify-center gap-1 rounded-t-lg border-b-2 px-4 py-2 ${
                          currentCategoryId === item.id
                            ? 'border-primary-eastern text-primary-eastern'
                            : 'border-transparent'
                        }
                        ${
                          isOpenCategory(item?.is_block_video || false)
                            ? ''
                            : 'cursor-not-allowed'
                        }
                          `}
                      >
                        {!isOpenCategory(item?.is_block_video || false) && (
                          <LockedCategoryIcon />
                        )}
                        <p
                          className={`text-PC-Button-Medium md:text-PC-Button-Medium w-max text-[#020509] ${
                            currentCategoryId === item.id
                              ? 'text-primary-eastern'
                              : ''
                          }
                           ${
                             isOpenCategory(item?.is_block_video || false)
                               ? ''
                               : 'text-[#AEB3B7]'
                           }
                            `}
                        >
                          {item.name}
                        </p>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {featureCourse ? (
            <>
              <div
                className={`flex py-2 md:px-3 ${
                  isOpenFeatureCourse ? '' : 'cursor-not-allowed'
                }`}
                onClick={handleClickFeaturePlaylist}
              >
                <div className="grid w-full md:grid-cols-2">
                  {isOpenFeatureCourse ? (
                    <Link
                      href={`${
                        UrlLink.knowledge.knowledge
                      }/${_format.convertTitleToSlug(
                        firstLessionNotBlock?.name || '',
                      )}-${firstLessionNotBlock?.id}`}
                    >
                      <div className="relative h-[236px] w-full md:h-[332px]">
                        <LazyImage
                          src={featureCourse?.thumbnail_url || ''}
                          alt="Thumbnail course"
                          fill
                          sizes="(max-width: 768px) 50vw, (max-width: 1024px) 30vw, 25vw"
                          className={`object-fit`}
                        />
                      </div>
                    </Link>
                  ) : (
                    <div className="relative h-[236px] w-full md:h-[332px]">
                      <LazyImage
                        src={featureCourse?.thumbnail_url || ''}
                        alt="Thumbnail course"
                        fill
                        sizes="(max-width: 768px) 50vw, (max-width: 1024px) 30vw, 25vw"
                        className={`object-fit grayscale`}
                      />
                      <BlockFillLarge className="absolute left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2" />
                    </div>
                  )}
                  <div className="flex w-full flex-col gap-3 py-8 md:px-4 lg:px-6">
                    {isOpenFeatureCourse ? (
                      <Link
                        href={`${
                          UrlLink.knowledge.knowledge
                        }/${_format.convertTitleToSlug(
                          firstLessionNotBlock?.name || '',
                        )}-${firstLessionNotBlock?.id}`}
                      >
                        <h3 className="text-PC-Display-H5 md:text-PC-Display-H3 font-header">
                          {featureCourse?.name}
                        </h3>
                      </Link>
                    ) : (
                      <h3 className="text-PC-Display-H5 md:text-PC-Display-H3 font-header opacity-50">
                        {featureCourse?.name}
                      </h3>
                    )}
                    <p
                      className={`font-sans text-[14px] font-semibold text-[#020509] lg:leading-[17.6px]
                        ${isOpenFeatureCourse ? '' : 'opacity-50'}
                        `}
                    >
                      {featureCourse?.lessons_aggregate?.aggregate?.count}{' '}
                      Videos
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:hidden">
                <HomeCardPlaylistFeature
                  data={featureCourse}
                  isLoading={loading}
                  nameFirstVideo={''}
                  idFirstVideo={0}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="relative w-full py-6 md:px-1">
          {listCourse?.length ? (
            <div>
              {listCourse?.length ? (
                <div>
                  <div className="b">
                    <Slider
                      {...{
                        ...settingsMobile,
                        infinite: lengSlide < 4 ? false : true,
                      }}
                      ref={customSlider}
                    >
                      {listCourse
                        ?.filter(item => item.id != featureCourse?.id)
                        ?.map((item, index) => {
                          return (
                            <div key={item?.id} className="!w-[288px] p-2">
                              <HomeCardPlaylist
                                key={index}
                                data={item}
                                isLoading={loading}
                                nameFirstVideo={item.lessons?.[0]?.name || ''}
                                idFirstVideo={item.lessons?.[0]?.id || 0}
                                onClickOpenModal={() =>
                                  setIsOpenModalDetail(true)
                                }
                                setClickedVideo={title => setUrlRedirect(title)}
                              />
                            </div>
                          );
                        })}
                    </Slider>
                    <div className="hidden lg:block">
                      <div
                        className={`left-[-50px] top-1/2  -translate-y-1/2 md:absolute ${
                          Number(listCourse?.length ?? 0) > 5
                            ? 'block'
                            : 'hidden'
                        }`}
                      >
                        <button onClick={goToPrev} className="cursor-pointer">
                          <LeftArrowIcon1 />
                        </button>
                      </div>
                      <div
                        className={`right-[-50px] top-1/2  -translate-y-1/2 md:absolute ${
                          Number(listCourse?.length ?? 0) > 5
                            ? 'block'
                            : 'hidden'
                        }`}
                      >
                        <button onClick={goToNext}>
                          <RightArrowIcon1 className="cursor-point" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default KnowledgePlaylistSection;
